// useDrag.js
import { useState, useRef } from 'react';

export const useDrag = (updateContainerSize, wasDragging) => {
  const [positions, setPositions] = useState({});
  const isDragging = useRef(false);
  const dragItem = useRef(null);
  const initialMousePos = useRef({ x: 0, y: 0 });
  const initialElementPos = useRef({ x: 0, y: 0 });
  const elementSize = useRef({ width: 0, height: 0 });
  const containerBounds = useRef({ width: 0, height: 0 });

  const handleDragStart = (e, index, images) => {
    const target = e.target;
    if (!target.classList.contains('tape-top-desktop') && !target.classList.contains('tape-corner-desktop')) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    
    dragItem.current = index;
    isDragging.current = true;
    if (wasDragging) wasDragging.current = false;

    // Guardar tamaño del elemento y contenedor
    const container = e.currentTarget.parentElement;
    containerBounds.current = {
      width: container.clientWidth,
      height: container.clientHeight
    };
    elementSize.current = {
      width: images[index].width,
      height: images[index].height
    };

    initialMousePos.current = {
      x: e.clientX,
      y: e.clientY
    };

    initialElementPos.current = {
      x: positions[index]?.x || images[index].x,
      y: positions[index]?.y || images[index].y
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    e.currentTarget.style.zIndex = '1000';
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    if (wasDragging) wasDragging.current = true;

    requestAnimationFrame(() => {
      const deltaX = e.clientX - initialMousePos.current.x;
      const deltaY = e.clientY - initialMousePos.current.y;

      // Calcular nueva posición
      let newX = initialElementPos.current.x + deltaX;
      let newY = initialElementPos.current.y + deltaY;

      // Limitar al área visible
      newX = Math.max(0, Math.min(newX, containerBounds.current.width - elementSize.current.width));
      newY = Math.max(0, Math.min(newY, containerBounds.current.height - elementSize.current.height));

      setPositions(prev => ({
        ...prev,
        [dragItem.current]: {
          x: newX,
          y: newY
        }
      }));
    });
  };

  const handleMouseUp = (e) => {
    if (isDragging.current) {
      e.preventDefault();
      e.stopPropagation();

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      
      const element = document.querySelector(`[data-index="${dragItem.current}"]`);
      if (element) {
        element.style.zIndex = '1';
      }

      setTimeout(() => {
        if (wasDragging) wasDragging.current = false;
      }, 100);

      isDragging.current = false;
      dragItem.current = null;
    }
  };

  const handleDrag = () => {};
  const handleDragEnd = () => {};

  return {
    positions,
    setPositions,
    handleDragStart,
    handleDrag,
    handleDragEnd,
    dragItem
  };
};
