import React from "react";
import "../styles/Header.css";

function Header() {
  return (
    <div className="header-container">
      <h1>Diario Mural</h1>
      <p>Pon tu aviso</p>
    </div>
  );
}

export default Header;
