// DiarioMuralMobile.js
import React, { useState, useRef, useEffect } from 'react';
import { getImages } from '../utils/photoUtils';
import { useDragMobile } from '../utils/useDragMobile';
import AbrirAviso from './AbrirAviso';
import '../styles/DiarioMuralMobile.css';

const DiarioMuralMobile = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [imageKey] = useState(Date.now());

  const generateImagePositionsMobile = () => {
    const baseHeight = 250;
    const spacing = 20; // Espacio consistente entre imágenes
    const sideMargin = 50; // Margen lateral para las imágenes
    const availableWidth = window.innerWidth - (sideMargin * 2); // Ancho disponible

    return getImages(imageKey).map((img, index) => ({
      ...img,
      x: sideMargin/2,
      y: index * (baseHeight + spacing),
      rotation: Math.random() * 6 - 3,
      width: availableWidth,
      height: baseHeight,
      zIndex: 1
    }));
  };

  const updateContainerSize = (images) => {
    if (!images.length) return;

    const lastImage = images[images.length - 1];
    const totalHeight = lastImage.y + lastImage.height + 50; // 50px de padding al final

    setContainerSize({ 
      width: window.innerWidth, 
      height: totalHeight
    });
  };

  const [images] = useState(generateImagePositionsMobile);
  const { positions, handleDragStart, handleDrag, handleDragEnd, dragItem } = useDragMobile(setContainerSize);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const newImages = generateImagePositionsMobile();
        updateContainerSize(newImages);
      }
    };

    // Asegurarse de que el tamaño inicial sea correcto
    updateContainerSize(images);
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [images]);

  const handleClick = (image) => {
    // Ocultar header y footer
    const header = document.querySelector('.app-header');
    const footer = document.querySelector('.app-footer');
    const main = document.querySelector('.app-main');
    
    if (header) header.style.visibility = 'hidden';
    if (footer) footer.style.visibility = 'hidden';
    if (main) {
      main.style.height = '100vh';
      main.style.position = 'fixed';  // Esto ayuda a asegurar que tome todo el viewport
      main.style.top = '0';
      main.style.left = '0';
      main.style.width = '100%';
    }
    
    setSelectedImage(image);
  };
  
  const handleCloseOverlay = () => {
    // Restaurar header y footer
    const header = document.querySelector('.app-header');
    const footer = document.querySelector('.app-footer');
    const main = document.querySelector('.app-main');
    
    if (header) header.style.visibility = 'visible';
    if (footer) footer.style.visibility = 'visible';
    if (main) {
      main.style.height = '73vh';  // O el valor original que tenías
      main.style.position = 'relative';
      main.style.top = 'auto';
      main.style.left = 'auto';
      main.style.width = 'auto';
    }
  
    setSelectedImage(null);
  };

  return (
    <div 
      className="photo-journal-mobile"
      style={{
        height: '100%',
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch'
      }}
    >
      <div 
        ref={containerRef}
        className="journal-container-mobile"
        style={{
          height: `${containerSize.height}px`,
          width: '100%',
          position: 'relative'
        }}
      >
        {images.map((image, index) => {
          const position = positions[index] || { x: image.x, y: image.y };
          
          return (
            <div
              key={image.id}
              className="photo-wrapper-mobile"
              style={{
                position: 'absolute',
                left: `${position.x}px`,
                top: `${position.y}px`,
                transform: `rotate(${image.rotation}deg)`,
                width: `${image.width}px`,
                height: `${image.height}px`,
                zIndex: dragItem.current === index ? 1000000 : image.zIndex,
                transition: dragItem.current === index ? 'none' : 'all 0.2s ease'
              }}
              onTouchStart={(e) => handleDragStart(e, index, images, containerSize)}
              onTouchMove={(e) => handleDrag(e, containerRef, images, containerSize)}
              onTouchEnd={handleDragEnd}
            >
              <div 
                className="photo-inner-mobile" 
                onClick={() => handleClick(image)}
              >
                <img 
                  src={image.src} 
                  alt={image.alt} 
                  draggable="false"
                />
                <div className="photo-overlay-mobile"></div>
                <div className="tape-top-mobile" />
                <div className="tape-corner-mobile" />
              </div>
            </div>
          );
        })}
      </div>

      {selectedImage && (
        <AbrirAviso
          photo={selectedImage}
          onClose={handleCloseOverlay}
        />
      )}
    </div>
  );
};

export default DiarioMuralMobile;
