// DiarioMural.js
import React from 'react';
import { isMobile } from '../utils/photoUtils';
import DiarioMuralDesktop from './DiarioMuralDesktop';
import DiarioMuralMobile from './DiarioMuralMobile';

const DiarioMural = () => {
  return isMobile() ? <DiarioMuralMobile /> : <DiarioMuralDesktop />;
};

export default DiarioMural;