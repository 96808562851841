// photoUtils.js
export const getImages = (imageKey) => [
    { id: 1, src: `/images/photo1.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 2, src: `/images/photo2.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 3, src: `/images/photo3.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 4, src: `/images/photo4.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 5, src: `/images/photo5.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 6, src: `/images/photo6.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 7, src: `/images/photo7.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 8, src: `/images/photo8.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 9, src: `/images/photo9.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 10, src: `/images/photo10.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 11, src: `/images/photo11.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 12, src: `/images/photo12.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 13, src: `/images/photo13.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 14, src: `/images/photo14.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 15, src: `/images/photo15.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 16, src: `/images/photo16.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 17, src: `/images/photo17.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 18, src: `/images/photo18.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 19, src: `/images/photo19.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 20, src: `/images/photo20.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 21, src: `/images/photo21.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 22, src: `/images/photo22.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 23, src: `/images/photo23.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 24, src: `/images/photo24.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 25, src: `/images/photo25.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 26, src: `/images/photo26.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 27, src: `/images/photo27.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 28, src: `/images/photo28.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 29, src: `/images/photo29.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 30, src: `/images/photo30.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 31, src: `/images/photo31.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 32, src: `/images/photo32.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 33, src: `/images/photo33.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 34, src: `/images/photo34.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 35, src: `/images/photo35.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 36, src: `/images/photo36.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 37, src: `/images/photo37.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 38, src: `/images/photo38.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 39, src: `/images/photo39.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 40, src: `/images/photo40.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 41, src: `/images/photo41.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 42, src: `/images/photo42.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 43, src: `/images/photo43.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 44, src: `/images/photo44.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 45, src: `/images/photo45.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 46, src: `/images/photo46.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 47, src: `/images/photo47.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 48, src: `/images/photo48.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 49, src: `/images/photo49.jpg?v=${imageKey}`, alt: "aviso" },
    { id: 50, src: `/images/photo50.jpg?v=${imageKey}`, alt: "aviso" }
  ];
  
  export const isMobile = () => window.innerWidth <= 768;