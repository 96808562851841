// useDragMobile.js
import { useState, useRef } from 'react';

export const useDragMobile = (updateContainerSize) => {
  const [positions, setPositions] = useState({});
  const isDragging = useRef(false);
  const dragItem = useRef(null);
  const dragInitialOffset = useRef({ touchY: 0, elementY: 0 });

  const handleDragStart = (e, index, images) => {
    const target = e.target;
    if (!target.classList.contains('tape-top-mobile') && !target.classList.contains('tape-corner-mobile')) {
      return;
    }

    if (e.type === 'touchstart') {
      e.preventDefault();
    }
    
    dragItem.current = index;
    isDragging.current = true;

    const clientY = e.touches[0].clientY;
    dragInitialOffset.current = {
      touchY: clientY,
      elementY: positions[index]?.y || images[index].y
    };

    e.currentTarget.style.zIndex = '1000';
  };

  const handleDrag = (e, containerRef, images, containerSize) => {
    if (!isDragging.current || dragItem.current === null) return;
    e.preventDefault();

    const clientY = e.touches[0].clientY;
    const deltaY = clientY - dragInitialOffset.current.touchY;
    const newY = Math.max(-1000, dragInitialOffset.current.elementY + deltaY);

    setPositions(prev => ({
      ...prev,
      [dragItem.current]: {
        x: 0,
        y: newY
      }
    }));

    const currentImage = images[dragItem.current];
    if (newY + currentImage.height + 100 > containerSize.height) {
      updateContainerSize(prev => ({
        ...prev,
        height: newY + currentImage.height + 100
      }));
    }
  };

  const handleDragEnd = (e) => {
    if (isDragging.current) {
      e.currentTarget.style.zIndex = '1';
      isDragging.current = false;
      dragItem.current = null;
    }
  };

  return {
    positions,
    setPositions,
    handleDragStart,
    handleDrag,
    handleDragEnd,
    dragItem
  };
};