import React, { useState } from "react";
import { ChevronRight, X } from 'lucide-react';
import "../styles/AbrirAviso.css";

const AbrirAviso = ({ photo, onClose }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = (e) => {
    e.stopPropagation();
    setShowDetails(!showDetails);
  };

  const isMobile = () => window.innerWidth <= 768;

  return (
    <div 
      className={`expanded-photo-overlay ${showDetails ? 'with-details' : ''}`}
      onClick={onClose}
    >
      <div 
        className="expanded-photo-container"
        onClick={e => e.stopPropagation()}
      >
        {/* Contenedor de la imagen */}
        <div className={`image-container ${showDetails && isMobile() ? 'hidden-mobile' : ''}`}>
          <div className="image-wrapper">
            <img
              src={photo.src}
              alt={photo.alt}
              className="main-image"
            />
          </div>
          <button
            className="close-button"
            onClick={onClose}
          >
            <X className="close-icon" />
          </button>

          <button
            className={`toggle-details-button ${showDetails ? 'rotated' : ''}`}
            onClick={toggleDetails}
            aria-label={showDetails ? 'Ver imagen' : 'Ver detalles'}
          >
            <ChevronRight className="arrow-icon" />
          </button>
        </div>

        {/* Panel de detalles */}
        {isMobile() && showDetails && (
              <>
                <button
                  className="close-button mobile-close"
                  onClick={onClose}
                >
                  <X className="close-icon" />
                </button>
                <button
                  className="toggle-details-button mobile-back"
                  onClick={toggleDetails}
                  aria-label="Ver imagen"
                >
                  <ChevronRight className="arrow-icon" />
                </button>
              </>
            )}
        <div className={`details-panel ${showDetails ? 'visible' : ''}`}>
        
          <div className="details-content">

            <div className="details-sections">
              <h2 className="details-title">
              🪄 ¡SE BUSCA BARBERO CON PEQUEÑAS AMBICIONES!
              </h2>
              
              <div className="details-section">
                <h3 className="section-title">
                  Descripción
                </h3>
                <p className="section-text">
                  ¿Cansado de ser leñador de bonsái? ¿Eres bajito pero tienes grandes aspiraciones? ¿Tus problemas de dinero se te hacen grandes? ¡Tenemos el trabajo de tus sueños!
                </p>
                <p className="section-text">
                  Requerimos:
                </p>

                <p className="section-text">
                <li>Estatura máxima 1.50m (el local tiene techo bajo... ¡es broma!)</li>
                <li>Experto en todo tipo de cortes.</li>
                <li>Capacidad de trabajo bajo presion</li>
                <li>Disfrutar del arte de "reducir cabezas" (metafóricamente, por favor)</li>
                </p>
                <p className="section-text">
                Beneficios:
                </p>
                <p className="section-text">
                <li>Silla hidráulica con boost x3 (alcanzarás hasta al más alto)</li>
                <li>No tener miedo a las alturas (proporcionamos taburete y casco)</li>
                <li>Seguro médico con altas prestaciones</li>
                </p>
              </div>

              <div className="details-section">
                <h3 className="section-title">
                  Datos de Contacto
                </h3>
                <div className="contact-info">
                  <p>Email: ejemplo@correo.com</p>
                  <p>Teléfono: +56 9 1234 5678</p>
                  <p>Ubicación: Santiago, Chile</p>
                </div>
              </div>

              <div className="details-section">
                <h3 className="section-title">
                  Detalles Adicionales
                </h3>
                <div className="additional-info">
                  <p>Fecha de publicación: 24/10/2024</p>
                  <p>Categoría: #Empleos</p>
                  <p>ID: {photo.id}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AbrirAviso;
