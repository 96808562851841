import React from "react";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <p>© 2024 Tiol</p>
    </div>
  );
}
export default Footer;
