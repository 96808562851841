// DiarioMuralDesktop.js
import React, { useState, useRef, useEffect } from 'react';
import { getImages } from '../utils/photoUtils';
import { useDrag } from '../utils/useDrag';
import AbrirAviso from './AbrirAviso';
import '../styles/DiarioMuralDesktop.css';

const DiarioMuralDesktop = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [imageKey] = useState(Date.now());
  const wasDragging = useRef(false);

  // estos valores seran parametrizables en el futuro
  const baseWidth = 250;
  const baseHeight = 250;
  const widthVariation = 50;
  const heightVariation = 50;
  const positionVariation = 40;
  const maxRotation = 6;
  const margin = -10;
  
  const calculateLayout = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight - 200; // Restamos el alto del header y footer
    const usableWidth = screenWidth * 0.9; // 90% del ancho de la pantalla

    // Ancho total necesario por item
    const itemTotalWidth = baseWidth + widthVariation + margin * 2;
    const itemTotalHeight = baseHeight + widthVariation + margin * 2;

    // Calcular número de columnas y filas
    const columnsCount = Math.floor(usableWidth / itemTotalWidth);
    const rowsCount = Math.floor(screenHeight / itemTotalHeight);

    return {
        maxItems: columnsCount * rowsCount,
        columns: columnsCount,
        columnWidth: usableWidth / columnsCount,
        rowHeight: baseHeight + widthVariation + margin,
    };
  };

  const generateImagePositionsDesktop = () => {
    // Obtener layout calculado
    const layout = calculateLayout();
    
    // Función para verificar superposición
    const checkOverlap = (newPos, positions, currentIndex) => {
        for (let i = 0; i < currentIndex; i++) {
            const pos = positions[i];
            const dx = Math.abs(newPos.x - pos.x);
            const dy = Math.abs(newPos.y - pos.y);
            const minDist = margin + (newPos.width + pos.width) / 2;
            
            if (dx < minDist && dy < minDist) {
                return true;
            }
        }
        return false;
    };

    const positions = [];
    
    return getImages(imageKey).slice(0, layout.maxItems).map((img, index) => {
        const row = Math.floor(index / layout.columns);
        const col = index % layout.columns;
        
        // Calcula la posición base centrada en la columna
        const baseX = (col * layout.columnWidth) + (layout.columnWidth - baseWidth) / 2;
        const baseY = row * layout.rowHeight;
        
        let position;
        let attempts = 0;
        
        do {
            const width = baseWidth + (Math.random() * widthVariation);
            const height = baseHeight + (Math.random() * heightVariation);
            
            position = {
                x: baseX + (Math.random() * positionVariation - positionVariation/2),
                y: baseY + (Math.random() * positionVariation - positionVariation/2),
                width: width,
                height: height,
                rotation: Math.random() * maxRotation * 2 - maxRotation
            };
            
            attempts++;
        } while (checkOverlap(position, positions, index) && attempts < 10);
        
        positions.push(position);
        
        return {
            ...img,
            ...position,
            zIndex: 1
        };
    });
  };

  const updateContainerSize = (images) => {
    if (!images.length) return;
    
    const maxX = Math.max(...images.map(img => img.x + img.width)) + 100;
    const maxY = Math.max(...images.map(img => img.y + img.height)) + 100;
    const minWidth = window.innerWidth * 0.9;
    
    setContainerSize({
      width: Math.max(minWidth, maxX),
      height: maxY
    });
  };

  const [images] = useState(generateImagePositionsDesktop);
  const { positions, handleDragStart, handleDrag, handleDragEnd, dragItem } = useDrag(updateContainerSize, wasDragging);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const newImages = generateImagePositionsDesktop();
        updateContainerSize(newImages);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (image) => {
    if (!wasDragging.current) {
      setSelectedImage(image);
    }
  };

  return (
    <div className="photo-journal-desktop">
      <div 
        ref={containerRef}
        className="journal-container-desktop"
        style={{
          //minHeight: `${containerSize.height}px`,
          //width: `${containerSize.width}px`
        }}
      >
        {images.map((image, index) => {
          const position = positions[index] || { x: image.x, y: image.y };
          
          return (
            <div
              key={image.id}
              data-index={index}
              className="photo-wrapper-desktop"
              style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
                transform: `rotate(${image.rotation}deg)`,
                width: `${image.width}px`,
                height: `${image.height}px`,
                zIndex: dragItem.current === index ? 1000 : image.zIndex
              }}
              onMouseDown={(e) => handleDragStart(e, index, images)}
            >
              <div className="photo-inner-desktop" onClick={() => handleClick(image)}>
                <img src={image.src} alt={image.alt} draggable="false" />
                <div className="photo-overlay-desktop"></div>
                <div className="tape-top-desktop" />
                <div className="tape-corner-desktop" />
              </div>
            </div>
          );
        })}
      </div>

      {selectedImage && (
        <AbrirAviso
          photo={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </div>
  );
};

export default DiarioMuralDesktop;