import React from "react";
import DiarioMural from "./components/DiarioMural";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <Header />
      </header>

      <main className="app-main">
        <DiarioMural />
      </main>

      <footer className="app-footer">
        <Footer />
      </footer>
    </div>
  );
}

export default App;
